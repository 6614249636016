import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default function ErrorPage() {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
      justifyContent={'center'}
      minHeight={'80vh'}
      width={'100%'}
    >
      <Typography align={'center'} color={'textPrimary'} variant={'h4'}>
        Page not found
      </Typography>
    </Box>
  );
}
